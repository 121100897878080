<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          New User
          <v-btn icon @click="$refs.user.reset()">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-avatar color="primary">
            <v-icon dark>mdi-account-plus</v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text>
          <v-form ref="user">
            <v-text-field
              label="Name"
              v-model="form.data.name"
              :rules="form.rules.name"
            ></v-text-field>
            <v-text-field
              label="Email"
              v-model="form.data.email"
              :rules="form.rules.email"
            ></v-text-field>
            <v-row>
              <v-col>
                <v-text-field
                  label="Password"
                  :type="password.type"
                  v-model="form.data.password"
                  :rules="form.rules.password"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Confirm Password"
                  :type="password.type"
                  :rules="form.rules.confirmpass"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn color="accent" @click="showPassword" text>
                <span>{{ password.text }}</span>
              </v-btn>
            </v-row>
            <v-autocomplete
              label="Role"
              :items="role"
              item-text="text"
              item-value="value"
              v-model="form.data.role"
              :rules="form.rules.role"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" large depressed @click="createUser">
            <span>save</span>
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" multi-line>
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.text }}
      <template v-slot:action>
        <v-btn text @click="snackbar.show = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      role: [
        { text: "Admin", value: "admin" },
        { text: "Agent", value: "agent" },
        { text: "Member", value: "member" },
      ],
      password: {
        show: false,
        text: "Show Password",
        type: "password",
      },
      form: {
        loading: false,
        disable: false,
        data: {
          name: null,
          email: null,
          password: null,
          role: null,
        },
        rules: {
          name: [
            (v) => !!v || "Name is required"
          ],
          email: [
            (v) => !!v || "Email is required",
            (v) => /.+@.+\..+/.test(v) || "Email must be valid",
          ],
          password: [(v) => !!v || "Password is required"],
          confirmpass: [
            (v) => !!v || "Confirm Your Password",
            (v) => v === this.form.data.password || "Password doesn't match",
          ],
          role: [(v) => !!v || "Role is required"],
        },
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
    };
  },
  methods: {
    ...mapActions(["newUser"]),
    showPassword() {
      this.password.show = !this.password.show;
      this.password.text = this.password.show
        ? "Hide Password"
        : "Show Password";
      this.password.type = this.password.show ? "text" : "password";
    },
    createUser() {
      let data = this.form.data;
      let isValid = this.$refs.user.validate();

      this.form.loading = true;
      this.form.disable = true;
      if (isValid) {
        if (this.newUser(data)) {
          this.form.loading = false;
          this.form.disable = false;
          this.showNotification("success");
        } else {
          this.form.loading = false;
          this.form.disable = false;
          this.showNotification("error");
        }
      } else {
        this.form.loading = false;
        this.form.disable = false;
        this.showNotification("error");
      }
    },
    showNotification(status) {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check";
          this.snackbar.text = "User Created";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close";
          this.snackbar.text = "Error. Failed to Create User";
          break;
      }
    },
  },
};
</script>